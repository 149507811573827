<template>
  <a-layout class="rays-tuner-simplifytool-create">
    <a-layout-content
      class="router_content"
      id="shader_simplifytool_create_scroll"
    >
      <a-form layout="vertical" :hideRequiredMark="true">
        <a-form-item class="title"> 创建任务 </a-form-item>
        <a-tooltip placement="top" :destroyTooltipOnHide="true">
          <template #title>
            <span>按住shift使用鼠标滚轮可以左右滚动</span>
          </template>
          <a-form-item class="frame" label="选择起始帧">
            <RecycleScroller
              id="shader_simplifytool_create_frames_scroll"
              class="pic_list_con ps"
              :items="frameList"
              :item-size="242"
              direction="horizontal"
              @resize="framesResize"
              @mousewheel.stop
              v-slot="{ item }"
            >
              <div class="pic_con" :data-picid="item.id">
                <div class="pic_heighlight" v-show="item.heighlight"></div>
                <a-image
                  :width="240"
                  :height="136"
                  :preview="false"
                  :src="item.url"
                  @click="onImgClick"
                  class="pic_list"
                  :fallback="imgSvg"
                />
                <div class="pic_selected" v-show="item.selected">
                  <svg-icon icon-class="checkmark" class="icon" />
                </div>
                <div class="pic_no" :class="{ no_heigh: item.heighlight }">
                  {{ item.id + 1 }}
                </div>
              </div>
            </RecycleScroller>
          </a-form-item>
        </a-tooltip>
        <a-form-item
          class="phone_model"
          v-bind="validateInfos.phoneModel"
          label="手机型号"
        >
          <a-select
            v-model:value="formState.phoneModel"
            placeholder="选择手机型号"
            size="large"
            :options="phoneOptions"
          >
          </a-select>
        </a-form-item>
        <a-form-item class="step" v-bind="validateInfos.step" label="Step">
          <!-- <a-input-number :min="1" :max="100000" v-model:value="formState.step" size="large" placeholder="请输入3-10之间的数字"/> -->
          <a-input
            v-model:value="formState.step"
            size="large"
            placeholder="请输入3-10之间的数字"
          >
          </a-input>
          <span class="form_tip">（请输入3-10之间的数字,默认推荐5）</span>
        </a-form-item>
        <a-form-item
          class="offset"
          v-bind="validateInfos.offset"
          label="Offset"
        >
          <a-slider
            :marks="offsetMarks"
            :max="offsetMax"
            @change="sliderChange"
            v-model:value="formState.offset"
          />
          <span class="form_tip"
            >（当前值{{ formState.offset }}，默认推荐6）</span
          >
        </a-form-item>
        <a-form-item
          class="resolution"
          v-bind="validateInfos.resolution"
          label="分辨率"
        >
          <a-input
            addon-before="W"
            v-model:value="formState.resolution[0]"
            size="large"
            placeholder="请输入数值（推荐1920）"
          >
          </a-input>
          <a-input
            addon-before="H"
            v-model:value="formState.resolution[1]"
            size="large"
            placeholder="请输入数值（推荐1080）"
          >
          </a-input>
          <span class="form_tip">（推荐1920*1080,不能大于手机分辨率）</span>
        </a-form-item>
        <a-checkbox class="auto_screen" v-model:checked="formState.autoScreen"
          >是否根据所有推荐点自动创建筛选任务</a-checkbox
        >
        <a-form-item class="submit_btn">
          <a-button @click="onSubmit" :loading="submiting">提交</a-button>
        </a-form-item>
      </a-form>
    </a-layout-content>
    <a-layout-sider class="right_slider" width="288">
      <div class="right_top">
        <simplify-highlight />
      </div>
      <div class="right_bot">
        <div class="right_frame_head">
          选择的帧范围
          <div class="frame_head_tip">
            {{ selectFrames.start.id * 60 || '?' }}-{{
              selectFrames.end.id * 60 || '?'
            }}
          </div>
        </div>
        <div class="right_frame_start">
          <a-image
            :width="240"
            :height="136"
            :src="selectFrames.start.url || ''"
            :preview="{
              onVisibleChange: imgPreviewSt,
            }"
            :fallback="imgSvg"
          />
          <div class="start_tip">初帧</div>
        </div>
        <div class="right_frame_end">
          <a-image
            :width="240"
            :height="136"
            :src="selectFrames.end.url || ''"
            :preview="{
              onVisibleChange: imgPreviewEd,
            }"
            :fallback="imgSvg"
          />
          <div class="end_tip">尾帧</div>
        </div>
      </div>
    </a-layout-sider>
  </a-layout>
</template>
<script>
import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
  reactive,
  computed,
  nextTick,
  createVNode,
} from 'vue';
import PerfectScrollbar from 'perfect-scrollbar';
import store from '@/vuex/store';
import { Form, message, Modal } from 'ant-design-vue';
import SimplifyHighlight from './SimplifyHighlight.vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import api from '@/api/shader-tools';
import { getBaseUrl } from '@/api/service';
const engineurl = getBaseUrl('engine');

const useForm = Form.useForm;

export default defineComponent({
  name: 'SimplifyToolCreate',
  components: {
    SimplifyHighlight,
  },
  setup() {
    let imgSvg =
      'data:image/svg+xml;utf8,<svg width="240" height="136" viewBox="-40 -40 100 100" fill="gray" ' +
      'xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" ' +
      'd="M19 17.834C19 18.478 18.552 19 18 19H6L13.566 12.183C13.812 11.938 14.258 11.939 14.499 ' +
      '12.182L19 16.66V17.834ZM8 7C8.828 7 9.5 7.672 9.5 8.5C9.5 9.328 8.828 10 8 10C7.172 10 6.5 9.328 ' +
      '6.5 8.5C6.5 7.672 7.172 7 8 7ZM18 3H6C4.346 3 3 4.346 3 6V18C3 19.654 4.346 21 6 21H18C19.654 21 ' +
      '21 19.654 21 18V6C21 4.346 19.654 3 18 3Z"/></svg>';
    let simplifyCreateDom = null;
    let simplifyCreatePs = null;
    let simplifyFramesDom = null;
    let simplifyFramesPs = null;
    const $_user = store.state.user.userinfo;
    const $_email = $_user.email;
    const $_pat = store.state.shaderTool.currentPat;

    const curShader = computed(() => store.state.shaderTool.currentShader);

    /**
     * {
     *      url:
     *      id:
     *      file:0000_l0_240x136_cmp.png
     * }
     */
    const frameList = ref([]);
    // for (let i = 0; i < 30; i++) {
    //     frameList.value.push({
    //         id: i,
    //         url: 'ss' + i
    //     })
    // }

    const phoneOptions = ref();
    const submiting = ref(false);
    const offsetMarks = ref({
      0: '0',
    });
    const offsetMax = ref(100);
    const formState = reactive({
      frame: [0, 0],
      phoneModel: undefined,
      step: 5,
      offset: 6,
      resolution: ['1920', '1080'],
      autoScreen: true,
    });

    const selectFrames = reactive({
      start: {},
      end: {},
    });

    const getSimplifyCreateFrames = async () => {
      const params = {
        user: $_email,
        user_id: $_user.uid,
        pat: $_pat.pat_name,
      };
      const res = await api.getSnapshotCmpList(params);
      if (!res) return;
      if (res.code != 0) {
        message.error('获取PAT帧图片失败:' + res.detail);
        return;
      }
      let _frameList = [];
      for (let i = 0; i < res.result_list.length; i++) {
        const _file = res.result_list[i];
        _frameList.push({
          id: i,
          file: _file,
          url:
            engineurl +
            '/api/sopt/simplifier/getSnapshotByFilename?filename=' +
            _file +
            '&pat=' +
            $_pat.pat_name +
            '&user=' +
            $_email,
          selected: false,
        });
      }
      offsetMax.value = _frameList.length - 1;
      offsetMarks.value = {
        0: '0',
      };
      offsetMarks.value[_frameList.length - 1] = _frameList.length - 1;
      frameList.value = _frameList;
      // console.log('getFrameList', _frameList, simplifyFramesPs);
      if (simplifyFramesPs && simplifyFramesDom) {
        nextTick(async () => {
          simplifyFramesPs.update();
          const frameParams = {
            user: $_email,
            pat: $_pat.pat_name,
            pid: curShader.value.shader_id,
          };
          const frameRes = await api.getAutomaticallyStartFrameNo(frameParams);
          if (!frameRes) return;
          if (frameRes.code != 0) {
            setSnapshotNo();
            return;
          }
          setSnapshotNo(frameRes.result || 0);
        });
      }
    };

    const setSnapshotNo = (no = 0) => {
      console.log('setSnapshotNo', no);
      simplifyFramesDom.scrollLeft = 242 * (no - 2);
      selectStartImg(no - 2, no);
    };

    const getUserDeviceModel = async () => {
      const params = {
        user: $_email,
        user_id: $_user.uid,
      };
      const res = await api.getUserDeviceModel(params);
      if (!res) return;
      if (res.code == 0) {
        const _res = JSON.parse(res.result_list);
        const _phList = [];
        for (let i = 0; i < _res.length; i++) {
          _phList.push({
            value: _res[i],
            label: _res[i],
          });
        }
        phoneOptions.value = _phList;
      } else {
        message.error('获取用户设备型号失败:' + res.detail);
      }
    };

    const addSimplifyTask = async () => {
      submiting.value = true;
      const params = {
        user: $_email,
        user_id: $_user.uid,
        pat: $_pat.pat_name,
        step: parseInt(formState.step),
        width: parseInt(formState.resolution[0]),
        height: parseInt(formState.resolution[1]),
        pid: curShader.value.shader_id,
        bmf: parseInt(formState.frame[0]) * 60,
        emf: parseInt(formState.frame[1]) * 60,
        device_type: formState.phoneModel || '',
        start_replay: formState.autoScreen || false,
      };
      const res = await api.addSimplifyTask(params);
      submiting.value = false;
      if (!res) return;
      if (res.code == 0) {
        message.success(
          '添加简化任务(' + (res.task_number || '') + ')成功！',
          4
        );
        store.commit('setShaderSimplifyTaskUpdate', new Date().getTime());
      } else {
        message.error('添加简化任务失败:' + res.detail);
      }
      store.commit('setMailUpdateTime', new Date().getTime());
    };

    const addConfirm = () => {
      Modal.confirm({
        // title: '添加简化任务',
        icon: createVNode(ExclamationCircleOutlined),
        content: '确定要添加简化任务吗？',
        okText: '确认',
        cancelText: '取消',
        onOk() {
          return new Promise((resolve) => {
            addSimplifyTask();
            resolve();
          });
        },
      });
    };

    const framesResize = () => {
      // console.log('framesResize:', simplifyFramesPs,simplifyFramesDom.scrollLeft);
      // 初始化图片列表scroll
      if (!simplifyFramesDom || !simplifyFramesPs) {
        simplifyFramesDom = document.querySelector(
          '#shader_simplifytool_create_frames_scroll'
        );
        if (!simplifyFramesPs) {
          simplifyFramesPs = new PerfectScrollbar(simplifyFramesDom);
        }
      }
      nextTick(() => {
        simplifyFramesPs.update();
      });
    };

    const selectStartImg = (id, heigh) => {
      for (let i = 0; i < frameList.value.length; i++) {
        const _frame = frameList.value[i];
        _frame.selected = false;
        if (_frame.id == id) {
          _frame.selected = true;
          formState.frame[0] = i;
          formState.frame[1] = i + formState.offset;
          selectFrames.start = _frame;
          selectFrames.end = frameList.value[i + formState.offset];
          let _offsetMax = frameList.value.length - 1 - i;
          offsetMarks.value = {
            0: '0',
          };
          offsetMax.value = _offsetMax;
          offsetMarks.value[_offsetMax] = _offsetMax;
        }
        if ((heigh || heigh === 0) && _frame.id == heigh) {
          _frame.heighlight = true;
        }
      }
    };

    const onImgClick = (event) => {
      console.log('onImgClick', event);
      let _path = event.target;
      while (_path) {
        if (_path.className == 'pic_con') {
          const _id = _path.getAttribute('data-picid');
          // console.log(_id);
          selectStartImg(_id);
          break;
        }
        _path = _path.parentNode;
      }
    };

    const sliderChange = (val) => {
      // console.log('sliderChange', val)
      formState.frame[1] = formState.frame[0] + val;
      selectFrames.end = frameList.value[formState.frame[0] + val];
    };

    const getSnapshot = (index, type) => {
      selectFrames[type].url =
        engineurl +
        '/api/sopt/simplifier/getSnapshot?index=' +
        index +
        '&pat=' +
        $_pat.pat_name +
        '&user=' +
        $_email;
    };

    const imgPreviewSt = (visible) => {
      // console.log('imgPreviewSt', visible);
      if (visible) {
        getSnapshot(selectFrames.start.id, 'start');
      }
    };

    const imgPreviewEd = (visible) => {
      // console.log('imgPreviewEd', visible);
      if (visible) {
        getSnapshot(selectFrames.end.id, 'end');
      }
    };

    let validateStep = async (rule, value) => {
      if (value === '') {
        return Promise.reject('输入的Step不能为空');
      } else {
        let _num = parseFloat(value);
        if (!Number.isInteger(_num)) {
          return Promise.reject('请输入整数');
        } else if (_num < 3 || _num > 10) {
          return Promise.reject('请输入3-10之间的数字');
        } else {
          return Promise.resolve();
        }
      }
    };

    let validateResolution = async (rule, value) => {
      if (value[0] === '' || value[1] === '') {
        return Promise.reject('输入的分辨率不能为空');
      } else {
        let _numW = parseFloat(value[0]);
        let _numH = parseFloat(value[1]);
        if (!Number.isInteger(_numW) || !Number.isInteger(_numH)) {
          return Promise.reject('请输入整数');
        } else if (_numW <= 0 || _numH <= 0) {
          return Promise.reject('请输入大于0的数字');
        } else {
          return Promise.resolve();
        }
      }
    };

    const rulesRef = reactive({
      phoneModel: [
        {
          required: true,
          message: '输入的手机型号不能为空',
          trigger: 'change',
        },
      ],
      step: [
        {
          required: true,
          validator: validateStep,
          trigger: ['change', 'blur'],
        },
      ],
      resolution: [
        { required: true, validator: validateResolution, trigger: 'blur' },
      ],
    });

    const { resetFields, validate, validateInfos } = useForm(
      formState,
      rulesRef
    );

    const onSubmit = () => {
      validate()
        .then(() => {
          addConfirm();
        })
        .catch((err) => {
          console.log('error', err);
        });
    };

    onMounted(() => {
      // console.log(process.env.NODE_ENV);
      // 初始化创建简化任务表单scroll
      simplifyCreateDom = document.querySelector(
        '#shader_simplifytool_create_scroll'
      );
      simplifyCreatePs = new PerfectScrollbar(simplifyCreateDom);

      // 初始化图片列表scroll
      if (!simplifyFramesDom || !simplifyFramesPs) {
        simplifyFramesDom = document.querySelector(
          '#shader_simplifytool_create_frames_scroll'
        );
        if (!simplifyFramesPs) {
          simplifyFramesPs = new PerfectScrollbar(simplifyFramesDom);
        }
      }
    });
    onBeforeUnmount(() => {
      if (simplifyCreatePs) {
        simplifyCreatePs.destroy();
        simplifyCreatePs = null;
      }
      simplifyCreateDom = null;

      if (simplifyFramesPs) {
        simplifyFramesPs.destroy();
        simplifyFramesPs = null;
      }
      simplifyFramesDom = null;
    });
    getUserDeviceModel();
    getSimplifyCreateFrames();
    return {
      phoneOptions,
      offsetMarks,
      offsetMax,
      formState,
      resetFields,
      validate,
      validateInfos,
      submiting,
      imgSvg,
      frameList,
      selectFrames,
      onImgClick,
      sliderChange,
      imgPreviewSt,
      imgPreviewEd,
      framesResize,
      onSubmit,
    };
  },
});
</script>

<style scoped lang="scss">
.pic_selected {
  position: absolute;
  top: 0;
  // width: 100%;
  // height: 100%;
  width: 240px;
  height: 136px;
  background: rgba(0, 0, 0, 0.5);
  .icon {
    width: 24px;
    height: 24px;
    float: right;
    margin-right: 8px;
    margin-top: 8px;
    color: #fff;
  }
}
.pic_heighlight {
  position: absolute;
  top: 0px;
  left: -2px;
  width: 244px;
  height: 139px;
  border: 2px dashed #604bdc;
}
.router_content {
  position: relative;
  padding: 32px 40px 100px 40px;
  border-right: 1px solid #d8d8d8;
  border-left: 1px solid #d8d8d8;
  background-color: #fff;
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Bold;
  font-weight: 700;
  text-align: LEFT;
  color: #3c3c3c;
  line-height: 22px;
  // form {
  //     width: 960px;
  // }
  input {
    border-radius: 4px;
  }
  .title {
    color: #222;
    font-size: 24px;
    line-height: 32px;
  }
  .frame {
    margin-top: 56px;
    margin-bottom: 42px;
    .pic_list_con {
      position: relative;
      height: 150px;
      border-radius: 2px;
      // overflow-x: hidden;
      .pic_con {
        position: relative;
        height: 136px;
        padding: 2px 0;
        .pic_no {
          position: absolute;
          top: 109px;
          right: 0;
          width: 25px;
          height: 26px;
          padding-top: 3px;
          background: rgba(0, 0, 0, 0.6);
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Bold;
          font-weight: 700;
          text-align: CENTER;
          color: #ffffff;
          line-height: 22px;
        }
        .no_heigh {
          padding-top: 2px;
          color: #fd0707;
          border-radius: 12px;
          border: 1px solid red;
        }
      }
    }
  }
  .form_tip {
    margin-left: 10px;
    color: #aaa;
  }
  .phone_model {
    width: 320px;
    height: 95px;
    margin-bottom: 8px;
  }
  .step {
    width: 580px;
    height: 95px;
    margin-bottom: 8px;
    input {
      width: 320px;
    }
  }
  .offset {
    width: 550px;
    // height: 94px;
    margin-bottom: 16px;
    .ant-slider {
      width: 314px;
      float: left;
    }
    .form_tip {
      margin-top: 10px;
      vertical-align: -8px;
    }
  }
  .resolution {
    width: 950px;
    height: 95px;
    margin-bottom: 4px;
    .form_tip {
      margin-top: 10px;
    }
  }
  .auto_screen {
    margin-bottom: 32px;
  }
  .submit_btn {
    margin-bottom: 0;
    button {
      width: 144px;
      height: 40px;
      border-radius: 4px;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 1px;
      color: #fff;
      border-color: #604bdc;
      background: #604bdc;
    }
  }
}
.right_slider {
  background-color: #fff;
  .right_top {
    height: 168px;
    padding: 16px 24px 16px 24px;
    border-bottom: 1px solid #d8d8d8;
  }
  .right_bot {
    padding: 24px 24px 0 24px;
    background-color: #fafafa;
    font-family: PingFang SC, PingFang SC-Bold;
    font-weight: 500;
    font-size: 14px;
    color: #fff;
    line-height: 26px;
    text-align: CENTER;
    .right_frame_head {
      height: 24px;
      font-size: 16px;
      text-align: LEFT;
      color: #222222;
      font-weight: 700;
      line-height: 24px;
      .frame_head_tip {
        float: right;
        font-size: 12px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        text-align: RIGHT;
        color: #3c3c3c;
      }
    }
    .right_frame_start {
      position: relative;
      width: 240px;
      height: 136px;
      margin-top: 40px;
      background-color: #222;
      cursor: pointer;
      .start_tip {
        position: absolute;
        width: 60px;
        height: 26px;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.6);
        border-radius: 2px;
      }
    }
    .right_frame_end {
      position: relative;
      width: 240px;
      height: 136px;
      margin-top: 32px;
      background-color: #222;
      cursor: pointer;
      .end_tip {
        position: absolute;
        width: 60px;
        height: 26px;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.6);
        border-radius: 2px;
      }
    }
  }
}
</style>
<style lang="scss">
.rays-tuner-simplifytool-create .frame .vue-recycle-scroller__item-view {
  margin-right: 2px;
}
.rays-tuner-simplifytool-create .frame .pic_list {
  border-radius: 2px;
  cursor: pointer;
  background-color: #222;
}
.rays-tuner-simplifytool-create .phone_model .ant-select-selector {
  border-radius: 4px;
}
.rays-tuner-simplifytool-create
  .resolution
  .ant-form-item-control-input-content {
  display: flex;
}
.rays-tuner-simplifytool-create .resolution .ant-input-group-wrapper {
  width: 320px;
  margin-right: 16px;
}
</style>
